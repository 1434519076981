import xhr from 'xhr'

function getStartboxMenu (menuID) {
  let productSelect = document.getElementById('product')
  let productButton = document.getElementById('info-box-button')
  let submitData = 'mode=getStartboxMenu&menuID=' + menuID
  xhr({
    useXDR: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: submitData,
    url: 'ajax.php'
  }, function (err, resp) {
    if (resp.statusCode === 200) {
      var data = JSON.parse(resp.body)
      if (data.html) {
        productSelect.innerHTML = data.html
        productButton.setAttribute('href', productSelect.value)
      }
      return false
    }
    if (err) {
      console.log(err)
    }
  })
}

export default function startboxMenue () {
  let productCat = document.getElementById('product-category')
  let productButton = document.getElementById('info-box-button')
  let productSelect = document.getElementById('product')
  if (productCat !== null) {
    productCat.addEventListener('change', function () {
      var menuID = this.value
      getStartboxMenu(menuID)
      return false
    }, false)
  }
  if (productSelect !== null) {
    productSelect.addEventListener('change', function () {
      var link = this.value
      productButton.setAttribute('href', link)
      return false
    }, false)
    productButton.setAttribute('href', productSelect.value)
  }
}
