/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */

import 'core-js/fn/object/assign'

import domready from 'domready'
import Swiper from 'swiper/dist/js/swiper.min'
import baguetteBox from 'baguettebox.js'

import mobileNavigation from './modules/mobile-navigation'
import toggleSubMenu from './modules/toggle-sub-menu'
import initHomeVisualization from './modules/home-visualization'
import formSubmit from './modules/form-submit'
import startboxMenue from './modules/startbox-menue'

/**
 * Ab geht die Reise
 */
domready(() => {
  // eslint-disable-next-line
  let slider = new Swiper('#slider .swiper-container', {
    direction: 'horizontal',
    loop: true,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    autoplay: 3000,
    autoplayDisableOnInteraction: false

  })

  // eslint-disable-next-line
  let linkCarousel = new Swiper('.link-carousel .swiper-container', {
    direction: 'horizontal',
    slidesPerView: 5,
    loop: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    breakpoints: {
      568: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 3
      }
    }
  })
// eslint-disable-next-line
  let productslider = new Swiper('.product-img .swiper-container', {
    loop: true,
    autoplay: 5000
  })

  document.body.addEventListener('click', toggleSubMenu)

  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })

  formSubmit()
  startboxMenue()
  initHomeVisualization({
    el: document.querySelector('.visualization-container')
  })
  baguetteBox.run('.lightbox')
})
