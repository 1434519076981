/** xhr-Modul zum Senden der Formulardaten */
import xhr from 'xhr'
import selectAll from './select-all'

let Popup = function (message, type) {
    this.message = '<div class="popup-content">' + message + '</div>'
    this.acceptButton = '<button id="popup-close-button" class="btn btn-primary ' + type + '">OK</button>'
    this.markup = '<div id="kdz-popup-overlay">' +
        '<div id="kdz-popup" class="popup ' + type + '">' +
        '<div class="popup-body">' + this.message + '</div>' +
        '<div class="popup-close-container">' + this.acceptButton + '</div>' +
        '</div>' +
        '</div>'
    this.open = function () {
        console.log('open')
        if (!document.getElementById('kdz-popup')) {
            document.body.insertAdjacentHTML('beforeend', this.markup)
        }
        document.getElementById('kdz-popup-overlay').classList.add('active')
        document.getElementById('kdz-popup').classList.add('active')
        document.getElementById('kdz-popup').classList.add('bounce-in-fwd')

        let closeBtn = document.getElementById('popup-close-button')
        closeBtn.addEventListener('click', this.close)
    }
    this.close = function () {
        document.getElementById('kdz-popup-overlay').remove()
    }
}

/**
 * Erstellt Eingabeobjekt aus FormData
 * @param  {Object} formData Formulardaten
 * @return {Object}          Eingabeobjekt
 */
function generateDataObject(form) {
    let data = {}
    let formElements = selectAll('[name]', form)
    formElements.map(el => {
        if (
            (el.getAttribute('type') !== 'radio' && el.getAttribute('type') !== 'checkbox') ||
            (el.getAttribute('type') === 'radio' && el.checked === true) ||
            (el.getAttribute('type') === 'checkbox' && el.checked === true)
        ) {
            let name = el.getAttribute('name')
            if (name !== undefined && name !== null && name !== 'g-recaptcha-response' && name !== 'undefined') {
                data[name] = {
                    value: el.value,
                    element: el,
                    required: el.hasAttribute('required')
                }

                if (el.tagName === 'INPUT') {
                    data[name].type = el.getAttribute('type') || 'text'
                }

                if (el.hasAttribute('data-verify')) {
                    data[name].regex = el.getAttribute('data-verify')
                }

                data[name].name = el.getAttribute('data-name') || el.getAttribute('placeholder')

                if (data[name].type === 'checkbox') {
                    var array = []
                    var checkboxes = document.querySelectorAll('input[name="' + name + '"]:checked')

                    for (var i = 0; i < checkboxes.length; i++) {
                        array.push(checkboxes[i].value)
                    }
                    data[name].value = array.join(', ')
                }
            }
        }
    })

    return data
}

/**
 * Setzt Attribute ob alle Eingaben valide sind
 * @param  {Object} data Eingabeobjekt
 * @return {Object}      Eingabeobjekt mit Validitätsattribut
 */
function verifyData(data) {
    Object.keys(data).map(key => {
        data[key].valid = verifyValue(data[key])
    })
    return data
}

/**
 * Validiert String anhand von Regulärem Ausdruck
 * @param  {RegExp} re    Regulärer Ausdruck
 * @param  {String} value Eingabestring
 * @return {Boolean}      Wert ob String valide ist
 */
function verifyRegex(re, value) {
    return re.test(value)
}

/**
 * Validierung einer Eingabe anhand von Typ oder Regulärem Ausdruck
 * @param  {Object} input Eingabeobjekt
 * @return {Boolean}      Wert ob Eingabe valide
 */
function verifyValue(input) {
    if (!input.required) {
        return true
    }

    if (input.value === '') {
        return false
    }

    if (input.type === 'email') {
        return verifyRegex(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, input.value)
    }

    if (input.type === 'number') {
        return verifyRegex(/(?:\d*\.)?\d+/g, input.value)
    }

    if (input.regex) {
        return verifyRegex(new RegExp(input.regex), input.value)
    }

    if (input.type === 'checkbox') {
        let cbID = String(input.element.id)
        if (cbID.includes('contact-datenschutz')) {
            let cb = document.getElementById(cbID)
            return cb.checked
        }

        if (cbID.includes('callback-datenschutz')) {
            let cb = document.getElementById(cbID)
            return cb.checked
        }
        return false
    }

    return true
}

/**s
 * Überprüfen ob alle Werte valide sind und Daten absenden
 * @param  {Object}   data     Formulardaten
 * @param  {Function} callback Callback Funktion zur Auswertung der Server Response
 */
function sendData(data, callback) {
    let invalid = []
    let invalidElements = []
    Object.keys(data).map(key => {
        if (!data[key].valid) {
            invalid.push(data[key].name)
            invalidElements.push(data[key].element)
        }
    })

    if (invalid.length > 0) {
        callback({
            message: `Bitte überprüfe folgende Eingabefelder: ${invalid}`,
            elements: invalidElements,
            status: 'error'
        })
        return false
    } else {
        xhr({
            body: JSON.stringify(data),
            uri: 'ajax.php',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        }, callback)
    }
}

// function showStatus (s, form) {
//   let statusMessage = form.querySelector('.status-message')
//   statusMessage.classList.add(s.status)
//   statusMessage.innerText = s.message
//
//   if (s.elements.length > 0) {
//     s.elements.map(el => el.classList.add('border-error'))
//   }
// }

/**
 * Daten laden und senden und Ergebnis auswerten
 * @param  {Object} e Dom Event
 */
function submit(e) {
    if (e.target.getAttribute('type') === 'submit' && !e.target.classList.contains('no-auto-post')) {
        e.preventDefault(e)

        let form = e.target.form;
        const callback = (err, res, body) => {
            if (err) {
                console.log({ err })
                // showStatus(err, form)
                let errorMessage = new Popup(err.message, 'error')
                errorMessage.open()
                return false
            }
            let responseBody = JSON.parse(body);
            let successMessage = new Popup(responseBody.message, 'success');
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'form_submitted',
                'formLocation': responseBody.mode
            });
            successMessage.open();
            form.reset()
        };

        let dataObject = verifyData(generateDataObject(form));

        if (form.id === "ratingForm") {
            const formData = new FormData();
            formData.append("file", form.elements["image"].files[0]);
            Object.keys(dataObject).forEach(key => {
                formData.append(key, dataObject[key].value);
                console.log(key + " => " + dataObject[key].value);
            });
            xhr({
                body: formData,
                uri: 'ajax.php',
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }, callback);

        } else {
            sendData(dataObject, callback);
        }
    }
}



/**
 * Einstieg für automatische Formularvalidierung und senden der Daten
 */
export default function formSubmit() {
    let forms = selectAll('form')
    forms.map(form => form.addEventListener('click', submit));
}
