/**
 * Schließt alle offenen Sub-Menus
 * @param  {Object} e DOM Event welches ausgelöst wurde
 */
function closeSubMenus (e) {
  let openSubMenus = document.querySelectorAll('.submenu.open')

  if (openSubMenus.length < 1) return false

  if (e.target.classList.contains('open')) return false

  openSubMenus.forEach((menu) => menu.classList.remove('open'))
}

/**
 * Öffnet und schließt Sub-Menus der Navigation
 * @param  {Object} e DOM Event welches ausgelöst wurde
 */
export default function toggleSubMenu (e) {
  if (e.target.classList.contains('submenu')) {
    e.preventDefault()
    closeSubMenus(e)
    e.target.classList.toggle('open')
  }
}
