import selectAll from './select-all'

const TOOLTIP_CLASSNAME = 'home-visualization-tooltip '

/**
 * Standardkonfiguration
 * @type {Object}
 */
const defaultOptions = {
  el: document.querySelector('#home-visualization')
}

/**
 * Informationen über interaktive Elemente sammeln
 * @param  {Object} opts Konfiguration
 * @return {Array}      Liste aller interaktiven Elemente
 */
function getInteractiveElements (opts) {
  return selectAll('.interactive', opts.el).map((element) => {
    return {
      el: element,
      content: document.querySelector('[data-visualization-id="' + element.id + '"]').innerHTML,
      img: document.querySelector('[data-visualization-id="' + element.id + '"]').getAttribute('data-img'),
      link: document.querySelector('[data-visualization-id="' + element.id + '"]').getAttribute('data-link'),
      titel: document.querySelector('[data-visualization-id="' + element.id + '"]').getAttribute('data-titel'),
      position: {
        x: document.querySelector(getTooltipMarker(element)).getBoundingClientRect().left,
        y: document.querySelector(getTooltipMarker(element)).getBoundingClientRect().top
      }
    }
  })
}

/**
 * Erstelle Tooltip-Marker ID
 * @param  {Object} element Interaktives DOM-Element
 * @return {String}         Tooltip-Marker ID
 */
function getTooltipMarker (element) {
  return '#marker-' + element.id
}

/**
 * Hinzufügen eines Tooltips
 * @param {Object} element Interaktives DOM-Element
 * @param {Object} root    DOM-Element der Grafik
 */
function addTooltip (element, root) {
  let container = document.getElementById('tip-container')
  container.innerHTML = ''
  // removeAllTooltips()
  selectAll('.interactive').map(tooltip => tooltip.classList.remove('active'))
  // Tooltips erst ab einer gewissen Displaygröße anzeigen
  let tooltip = document.createElement('div')
  element.el.classList.add('active')
  tooltip.className = TOOLTIP_CLASSNAME
  tooltip.id = 'tooltip-' + element.el.id
  tooltip.setAttribute('data-marker', getTooltipMarker(element.el))
  let img = ''
  if (element.img) {
    img = `<div class="tip-img">
        <img id="tooltip-img" class="pure-img" src="${element.img}" title="${element.titel}" alt="${element.titel}">
      </div>`
  }
  let html = `<div id="home-visualization-tooltip" class="home-visualization-tooltip fade">
      ${img}
      <div id="tooltip-content" class="tip-content">
        ${element.content}
      </div>
      <a id="tooltip-link" class="tip-btn" href="${element.link}" title="${element.titel}">mehr erfahren</a>
    </div>`
  container.innerHTML = html
}

/**
 * Toggelt Tooltip eines interaktiven Elements
 * @param  {Object} element Element dessen Tooltip getoggelt werden soll
 * @param  {Object} root    DOM-Element der Grafik
 */
function toggleTooltip (element, root) {
  addTooltip(element, root)
}

/**
 * Initialisierung der interaktiven Elemente
 * @param  {Object} opts Konfiguration
 */
function initialize (opts) {
  getInteractiveElements(opts).map(el => {
    el.el.addEventListener('click', e => toggleTooltip(el, opts.el))
    el.el.addEventListener('touchstart', e => toggleTooltip(el, opts.el))
  })
}

/**
 * Initialisierung der interaktiven Hausgrafik
 * @param  {Object} opts Konfiguration
 */
export default function initHomeVisualization (opts) {
  const options = Object.assign(defaultOptions, opts)
  if (options.el) {
    console.log(`
      --------------------------------
      Hausvisualisierung initialisiert
      --------------------------------
    `)
    initialize(options)
  } else {
    console.log(`
      --------------------------------------
      Hausvisualisierung nicht initialisiert
      --------------------------------------
      Bitte wähle ein korrektes DOM-Element.
    `)
  }
}
